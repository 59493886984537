import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Icon,
  Input,
  Textarea,
  Typography,
} from '@wisecare-tech/design-system-web';
import { useFormContext } from 'react-hook-form';
import {
  Container,
  Header,
  Body,
  InlineForms,
  TextAreaForm,
  selectStyles,
  Required,
  SelectLabel,
} from './styles/StyledRequest';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import _ from 'lodash';
import { FormData } from './CreateRequest';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import SearchSelect from '../UI/searchSelect';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { AlertMessage } from '../messages/AlertMessage';
import { ListOnCall } from '~/domain/usecases/duty/remote';
import { useLocation } from 'react-router';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { IconContainer } from '../UI/searchSelect/styles';

interface Option {
  readonly label: string;
  readonly value: string;
}

interface Info {
  info: {
    city: {
      id: number;
      name: string;
    };
    healthUnitId: number;
    healthUnitName: string;
  };
}

const Request = () => {
  const [cities, setCities] = useState<GetCitiesByUf.Model>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [specialty, setSpecialty] = useState<ListOnCall.Model['records']>();

  const params = useLocation<{ info: Info['info'] }>()?.state;

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { results: consultants } = useSelector(
    (store: iStore) => store.consultant,
  );

  const {
    orgId,
    role,
    orgUnits: orgUnitsSelectUser,
  } = useSelector((store: iStore) => store.auth.selectUser);

  const { results: orgUnits } = useSelector((store: iStore) => store.orgUnit);

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });

    makeRemoteListOnCall()
      .get({})
      .then(res => {
        setSpecialty(res.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao carregar especialidades!',
          type: 'danger',
        });
      });

    makeReduxGetAllUsers().getAll({
      pageSize: 9999,
    });

    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: 'BRA',
      })
      .then(res => {
        setRegionalDocs(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });

    makeReduxGetAllOrgUnitList().list({
      pageSize: 9999,
      filter: {
        org: orgId,
      },
    });

    const orgUnitsFormatted =
      (orgUnitsSelectUser?.map(item => item.id).filter(Boolean) as number[]) ??
      [];

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgId]);

  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormData>();

  const createOption = (label: string, value?: string) => ({
    label,
    value: value ?? '',
  });

  const resetValues = () => {
    setValue('sex', '');
    setValue('birthDate', '');
    setValue('motherName', '');
    setValue('phone', '');
    setValue('regType', '');
    setValue('regValue', '');
    setValue('orgUnit', undefined);
  };

  const options = useMemo(() => {
    return consultants?.map(value =>
      createOption(
        `${value?.user?.firstName} ${value?.user?.lastName}`,
        String(value?.consultant?.id),
      ),
    );
  }, [consultants]);

  const generateCitySelectOptions = () => {
    return [
      {
        label: params?.info?.city?.name,
        value: Number(watch('county')),
      },
    ];
  };

  const cityMultiselectSelected = useMemo(() => {
    return generateCitySelectOptions().find(option => {
      return option?.value === Number(watch('county'));
    });
  }, [generateCitySelectOptions, watch('county')]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    if (!watch('county')) return [];

    return [
      {
        label: params?.info?.healthUnitName,
        value: params?.info?.healthUnitId,
      },
    ];
  }, [healthUnits]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(option => {
      return option?.value === Number(watch('healthUnit'));
    });
  }, [generateHealthUnitSelectOptions, watch('healthUnit')]);

  const generatePrioritySelectOptions = () => {
    const options = [
      { label: 'Baixa - Até 72 horas', value: 'LOW' },
      { label: 'Média - Até 48 horas', value: 'NORMAL' },
      { label: 'Alta - Até 24 horas', value: 'EMERGENCY' },
    ];

    const optionsMap = options?.flatMap(value => {
      return [
        {
          label: value?.label,
          value: value?.value,
        },
      ];
    });

    return optionsMap;
  };

  const priorityMultiselectSelected = useMemo(() => {
    return generatePrioritySelectOptions().find(
      option => option?.value === watch('responsePriority'),
    );
  }, [generatePrioritySelectOptions]);

  const generateStateSelectOptions = () => {
    return [
      {
        label: 'Paraíba',
        value: 'PB',
      },
    ];
  };

  const stateMultiselectSelected = useMemo(() => {
    return generateStateSelectOptions().find(
      option => option?.value === watch('state'),
    );
  }, [generateStateSelectOptions]);

  const generateSpecialtySelectOptions = () => {
    return _.sortBy(
      specialty?.flatMap(value => {
        return [
          {
            label: value?.descr,
            value: value?.id,
          },
        ];
      }),
      item => item?.label?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const specialtyMultiSelectSelected = useMemo(() => {
    if (!watch('specialty')) return [];

    return generateSpecialtySelectOptions().find(
      item => item?.value === Number(watch('specialty')),
    );
  }, [generateSpecialtySelectOptions]);

  const handleCreate = useCallback(
    (inputValue: string) => {
      setIsLoading(true);
      setTimeout(() => {
        const newOption = createOption(inputValue);
        setValue('fullName', newOption.label);
        resetValues();
        setIsLoading(false);
        setUserId(undefined);
        setValue('isDisabled', false);
      }, 1000);
    },
    [options],
  );

  const fullNameSelected = () => {
    if (!watch('fullName')) return [];

    return {
      label: watch('fullName'),
      value: '',
    };
  };

  const generateSexSelectOptions = () => {
    return [
      {
        value: 'MALE',
        label: 'Masculino',
      },
      {
        value: 'FEMALE',
        label: 'Feminino',
      },
    ];
  };

  const sexSelected = () => {
    if (!watch('sex')) return [];

    return generateSexSelectOptions().find(
      option => option.value === watch('sex'),
    );
  };

  const generateDocTypeSelectOptions = () => {
    return _.sortBy(
      regionalDocs?.flatMap(value => {
        return [
          {
            value: value?.id,
            label: value?.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const docTypeSelected = () => {
    return generateDocTypeSelectOptions().find(
      option => option.label === watch('regType'),
    );
  };

  const generateOrgUnitSelectOptions = () => {
    return _.sortBy(
      orgUnits?.flatMap(value => {
        return [
          {
            value: value?.orgUnit?.id,
            label: value?.orgUnit?.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const orgUnitSelected = () => {
    return generateOrgUnitSelectOptions().find(
      option => Number(option.value) === watch('orgUnit'),
    );
  };

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: watch('county'),
    });
  }, [watch('county')]);

  useEffect(() => {
    if (userId) {
      register('isDisabled');
      setValue('isDisabled', true);
      const user = consultants?.find(value => value?.consultant?.id === userId);

      if (user) {
        const { motherName, firstName, lastName, birthdate, phone, gender } =
          user?.user;

        const data = new Date(birthdate);

        const dateFormatted = format(data, 'dd/MM/yyyy');

        setValue('fullName', `${firstName} ${lastName}`);
        setValue('sex', gender);
        setValue('birthDate', birthdate ? dateFormatted : undefined);
        setValue('motherName', motherName);
        setValue('phone', phone);
        setValue('regType', user?.documents?.[0]?.acronym);
        setValue('regValue', user?.documents?.[0]?.value);
      }
    }
  }, [userId, consultants]);

  useEffect(() => {
    if (!params?.info) return;

    setValue('healthUnit', String(params?.info?.healthUnitId));
    setValue('county', String(params?.info?.city?.id));
  }, [params]);

  console.log('>>> Errors: ', errors);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        gap: '16px',
        marginBottom: '98px',
      }}
    >
      <Container>
        <Header>
          <Typography variant="t3_16semibold">Dados do paciente</Typography>
        </Header>
        <Body>
          <div>
            <SearchSelect
              {...(register('fullName') as Option | null | undefined)}
              className="select"
              id="fullName"
              isDisabled={isLoading}
              isLoading={isLoading}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              onChange={newValue => {
                setValue('fullName', newValue?.label);
                if (newValue?.value) {
                  register('consultant');
                  setValue('consultant', Number(newValue?.value));
                  setUserId(Number(newValue?.value));
                }
              }}
              onCreateOption={handleCreate}
              formatCreateLabel={(label: string) => {
                if (!label?.length) return '';
                return `Paciente externo: ${label}`;
              }}
              options={options}
              value={fullNameSelected() as any}
              error={Boolean(errors?.fullName)}
              message={errors?.fullName?.message}
              label="Nome completo"
              placeholder="Digite ou selecione aqui"
              styles={selectStyles()}
              isValidNewOption={() => true}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </div>

          <InlineForms>
            <SearchSelect
              className="select"
              {...(register('sex') as any)}
              isDisabled={watch('isDisabled')}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              options={generateSexSelectOptions()}
              value={sexSelected()}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('sex', e.value);
              }}
              controlShouldRenderValue
              defaultValue={watch('sex')}
              error={errors?.sex}
              message={errors?.sex?.message}
              label="Sexo"
              required
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <TextAreaForm>
              <SelectLabel>Data de nascimento</SelectLabel>
              <InputMask
                mask="99/99/9999"
                {...(register('birthDate') as any)}
                onChange={e => {
                  setValue('birthDate', e.target.value);
                }}
                placeholder="DD/MM/AAAA"
                value={watch('birthDate')}
                disabled={watch('isDisabled')}
              >
                <Input
                  height="40px"
                  required
                  value={watch('birthDate')}
                  helperText={errors?.birthDate?.message}
                  status={errors?.birthDate ? 'error' : 'default'}
                />
              </InputMask>
            </TextAreaForm>

            <SearchSelect
              className="select"
              {...(register('regType') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              isDisabled={watch('isDisabled')}
              options={generateDocTypeSelectOptions()}
              value={docTypeSelected()}
              onChange={(e: { value: string; label: string }) => {
                if (!e) return;
                setValue('regType', String(e?.label));
                setValue('regValue', '');
              }}
              controlShouldRenderValue
              defaultValue={watch('regType')}
              error={errors?.regType}
              required
              message={errors?.regType?.message}
              label="Documento de identificação"
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <TextAreaForm>
              <div style={{ marginBottom: '14px' }} />
              <Input
                {...(register('regValue') as any)}
                height="40px"
                onChange={e => {
                  setValue('regValue', e.target.value.replace(/\D/g, ''));
                }}
                disabled={watch('isDisabled')}
                required
                defaultValue={watch('regValue')}
                value={watch('regValue')}
                error={Boolean(errors?.regValue)}
                helperText={errors?.regValue?.message}
                status={errors?.regValue ? 'error' : 'default'}
              />
            </TextAreaForm>
          </InlineForms>
        </Body>
      </Container>
      <Container>
        <Header>
          <Typography variant="t3_16semibold">Dados do atendimento</Typography>
        </Header>
        <Body>
          <InlineForms>
            <SearchSelect
              {...(register('state') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              className="select"
              controlShouldRenderValue
              options={generateStateSelectOptions()}
              value={stateMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('state', e?.value);
                setValue('county', '');
                setValue('healthUnit', '');
              }}
              isDisabled
              placeholder="Selecione"
              formatCreateLabel={(label: string) => ({
                label: `Buscar por ${label}`,
              })}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              label="Estado"
              error={errors?.state}
              message={errors?.state?.message}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="20%"
            />

            <SearchSelect
              className="select"
              {...(register('county') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              options={generateCitySelectOptions()}
              value={cityMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('county', String(e?.value));
                setValue('healthUnit', '');
              }}
              controlShouldRenderValue
              defaultValue={watch('county')}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              label="Município"
              isDisabled={!!cityMultiselectSelected?.value}
              required
              error={errors?.county}
              message={errors?.county?.message}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="30%"
            />

            <SearchSelect
              {...(register('healthUnit') as any)}
              className="select"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              defaultValue={watch('healthUnit')}
              options={generateHealthUnitSelectOptions()}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              onChange={(e: { value: string }) => {
                console.log('##e', e);
                setValue('healthUnit', String(e?.value));
              }}
              value={healthUnitMultiselectSelected}
              isValidNewOption={() => false}
              label="Unidade de Saúde"
              error={errors?.healthUnit}
              isDisabled={!!healthUnitMultiselectSelected?.value}
              message={errors?.healthUnit?.message}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="50%"
            />
          </InlineForms>

          <InlineForms>
            <SearchSelect
              {...(register('orgUnit') as any)}
              className="select"
              id="orgUnit"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              options={generateOrgUnitSelectOptions()}
              value={orgUnitSelected()}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('orgUnit', Number(e?.value));
              }}
              placeholder="Selecione"
              label="Setor"
              width="20%"
              error={errors?.orgUnit}
              message={errors?.orgUnit?.message && 'Campo obrigatório'}
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <SearchSelect
              {...(register('specialty') as any)}
              className="select"
              id="specialty"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              options={generateSpecialtySelectOptions()}
              value={specialtyMultiSelectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('specialty', String(e?.value));
              }}
              placeholder="Selecione"
              label="Especialidade do atendimento"
              width="30%"
              error={errors?.specialty}
              message={errors?.specialty?.message}
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <SearchSelect
              className="select"
              id="responsePriority"
              {...(register('responsePriority') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              options={generatePrioritySelectOptions()}
              value={priorityMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('responsePriority', String(e?.value));
              }}
              width="50%"
              label="Prioridade de resposta"
              error={errors?.responsePriority}
              message={errors?.responsePriority?.message}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </InlineForms>

          <TextAreaForm>
            <Textarea
              {...(register('description') as any)}
              defaultValue={watch('description')}
              placeholder="Descreva o motivo pela qual solicita este laudo."
              onChange={e => setValue('description', e.target.value)}
              label="Subjetivo e Objetivo"
              required
            />

            <IconContainer display={!!errors?.description}>
              <Icon name="error" color="red-7" size="medium" />
              <Typography variant="b2_14semibold" className="tw-text-red-7">
                {errors?.description?.message}
              </Typography>
            </IconContainer>
          </TextAreaForm>
        </Body>
      </Container>
    </div>
  );
};

export default Request;
