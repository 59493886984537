import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import {
  Icon,
  Input as InputDs,
  Textarea,
  Typography,
} from '@wisecare-tech/design-system-web';
import { format } from 'date-fns';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { FormData } from './CreateReport';
import {
  Container,
  InlineForms,
  TextAreaForm,
  selectStyles,
  Required,
  SelectLabel,
} from './styles/StyledPatient';
import { Input } from '../UI';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import SearchSelect from '../UI/searchSelect';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { IconContainer } from '../UI/searchSelect/styles';

interface Option {
  readonly label: string;
  readonly value: string;
}

const Patient = () => {
  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext<FormData>();

  const { results } = useSelector((store: iStore) => store.consultant);

  const { orgId, role, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const createOption = (label: string, value?: string) => ({
    label,
    value: value ?? '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [userId, setUserId] = useState<number | undefined>(undefined);

  const options = useMemo(() => {
    return results?.map(value =>
      createOption(
        `${value?.user?.firstName} ${value?.user?.lastName}`,
        String(value?.consultant?.id),
      ),
    );
  }, [results]);

  const resetValues = () => {
    setValue('sex', '');
    setValue('birthDate', '');
    setValue('motherName', '');
    setValue('phone', '');
    setValue('regType', '');
    setValue('regValue', '');
  };

  const handleCreate = useCallback(
    (inputValue: string) => {
      setIsLoading(true);
      setTimeout(() => {
        const newOption = createOption(inputValue);
        setValue('fullName', newOption.label);
        resetValues();
        setIsLoading(false);
        setUserId(undefined);
        setValue('isDisabled', false);
      }, 1000);
    },
    [options],
  );

  const fullNameSelected = () => {
    if (!watch('fullName')) return [];

    return {
      label: watch('fullName'),
      value: '',
    };
  };

  const generateSexSelectOptions = () => {
    return [
      {
        value: 'MALE',
        label: 'Masculino',
      },
      {
        value: 'FEMALE',
        label: 'Feminino',
      },
    ];
  };

  const sexSelected = () => {
    if (!watch('sex')) return [];

    return generateSexSelectOptions().find(
      option => option.value === watch('sex'),
    );
  };

  const generateDocTypeSelectOptions = () => {
    return _.sortBy(
      regionalDocs?.flatMap(value => {
        return [
          {
            value: value?.id,
            label: value?.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const docTypeSelected = () => {
    return generateDocTypeSelectOptions().find(
      option => option.label === watch('regType'),
    );
  };

  useEffect(() => {
    makeReduxGetAllUsers().getAll({
      pageSize: 9999,
    });

    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: 'BRA',
      })
      .then(res => {
        setRegionalDocs(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, []);

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgUnits, orgId, role]);

  useEffect(() => {
    if (userId) {
      register('isDisabled');
      setValue('isDisabled', true);
      const user = results?.find(value => value?.consultant?.id === userId);

      if (user) {
        const { motherName, firstName, lastName, birthdate, phone, gender } =
          user?.user;

        const data = new Date(birthdate);

        const dateFormatted = format(data, 'dd/MM/yyyy');

        setValue('fullName', `${firstName} ${lastName}`);
        setValue('sex', gender);
        setValue('birthDate', dateFormatted);
        setValue('motherName', motherName ?? '');
        setValue('phone', phone);
        setValue('regType', user?.documents?.[0]?.acronym);
        setValue('regValue', user?.documents?.[0]?.value);
      }
    }
  }, [userId, results]);

  return (
    <Container>
      <InlineForms>
        <SearchSelect
          {...(register('fullName') as Option | null | undefined)}
          className="select"
          id="fullName"
          isDisabled={isLoading}
          isLoading={isLoading}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          onChange={newValue => {
            setValue('fullName', newValue?.label);
            if (newValue?.value) {
              register('consultant');
              setValue('consultant', Number(newValue?.value));
              setUserId(Number(newValue?.value));
            }
          }}
          onCreateOption={handleCreate}
          formatCreateLabel={(label: string) => {
            if (!label?.length) return '';
            return `Paciente externo: ${label}`;
          }}
          options={options}
          value={fullNameSelected() as any}
          error={Boolean(errors?.fullName)}
          message={errors?.fullName?.message}
          label="Nome completo"
          width="70%"
          placeholder="Selecione ou digite o nome de um paciente"
          styles={selectStyles()}
          isValidNewOption={() => true}
          required
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />

        <SearchSelect
          className="select"
          {...(register('sex') as any)}
          isDisabled={watch('isDisabled')}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          options={generateSexSelectOptions()}
          value={sexSelected()}
          onChange={(e: { value: string }) => {
            if (!e) return;
            setValue('sex', e.value);
          }}
          controlShouldRenderValue
          defaultValue={watch('sex')}
          error={errors?.sex}
          message={errors?.sex?.message}
          width="30%"
          label="Sexo"
          required
          placeholder="Escolha uma opção"
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          isValidNewOption={() => false}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />
      </InlineForms>

      <InlineForms>
        <TextAreaForm>
          <SelectLabel required>Data de nascimento</SelectLabel>
          <InputMask
            mask="99/99/9999"
            {...(register('birthDate') as any)}
            onChange={e => {
              setValue('birthDate', e.target.value);
            }}
            placeholder="DD/MM/AAAA"
            value={watch('birthDate')}
            disabled={watch('isDisabled')}
          >
            <InputDs
              height="40px"
              required
              value={watch('birthDate')}
              helperText={errors?.birthDate?.message}
              status={errors?.birthDate ? 'error' : 'default'}
            />
          </InputMask>
        </TextAreaForm>

        <SearchSelect
          className="select"
          {...(register('regType') as any)}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          isDisabled={watch('isDisabled')}
          options={generateDocTypeSelectOptions()}
          value={docTypeSelected()}
          onChange={(e: { value: string; label: string }) => {
            if (!e) return;
            setValue('regType', String(e?.label));
            setValue('regValue', '');
          }}
          controlShouldRenderValue
          defaultValue={watch('regType')}
          error={errors?.regType}
          required
          message={errors?.regType?.message}
          label="Documento de identificação"
          placeholder="Escolha um documento"
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          isValidNewOption={() => false}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />

        <TextAreaForm>
          <div style={{ marginBottom: '14px' }} />
          <InputDs
            {...(register('regValue') as any)}
            height="40px"
            onChange={e => {
              setValue('regValue', e.target.value.replace(/\D/g, ''));
            }}
            disabled={watch('isDisabled')}
            required
            defaultValue={watch('regValue')}
            value={watch('regValue')}
            error={Boolean(errors?.regValue)}
            helperText={errors?.regValue?.message}
            status={errors?.regValue ? 'error' : 'default'}
          />
        </TextAreaForm>
      </InlineForms>

      <InlineForms>
        <InputDs
          {...(register('motherName') as any)}
          placeholder="Digite o nome da mãe"
          required={watch('consultant') ? false : true}
          id="motherName"
          height="40px"
          defaultValue={watch('motherName')}
          disabled={watch('isDisabled') && watch('motherName')}
          value={watch('motherName')}
          label="Nome da mãe"
          helperText={errors?.motherName?.message}
          status={errors?.motherName ? 'error' : 'default'}
          error={Boolean(errors?.motherName)}
          onChange={e => {
            setValue('motherName', e.target.value);
          }}
        />

        <TextAreaForm>
          <SelectLabel required>Contato</SelectLabel>
          <InputMask
            mask="(99) 99999-9999"
            {...(register('phone') as any)}
            onChange={e => {
              setValue('phone', e.target.value);
            }}
            defaultValue={watch('phone')}
            value={watch('phone')}
            disabled={watch('isDisabled') && watch('phone')}
            required
          >
            <InputDs
              className="hover-input"
              placeholder="(DD) 00000-0000"
              helperText={errors?.phone?.message}
              status={errors?.phone ? 'error' : 'default'}
              height="40px"
              id="contact"
            />
          </InputMask>
        </TextAreaForm>
      </InlineForms>

      <TextAreaForm>
        <Textarea
          {...(register('patientDescription') as any)}
          onChange={e => {
            setValue('patientDescription', e.target.value);
          }}
          label="Informações do paciente"
          defaultValue={watch('patientDescription')}
          placeholder="Qualquer informação relevante para o especialista, a exemplo do histórico médico, medicamentos em uso, OPME, hábitos, etc. pessoais."
        />

        <IconContainer display={!!errors?.patientDescription}>
          <Icon name="error" color="red-7" size="medium" />
          <Typography variant="b2_14semibold" className="tw-text-red-7">
            {errors?.patientDescription?.message}
          </Typography>
        </IconContainer>
      </TextAreaForm>
    </Container>
  );
};

export default Patient;
